import React, { useState, useEffect } from "react";
import {useStaticQuery, graphql } from 'gatsby'
import { Container, Col, Row, Form } from 'react-bootstrap';
import get from 'lodash/get'
import { Link } from "gatsby";
import Header from "../components/Header2/header2";

import Breadcrumbs from "../components/breadcrumbs";
import InnerBanner from "../components/inner-banner"
import InnerDetail from "../components/inner-detail"
import OtherNews from "../components/othernewsSection"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown/with-html"
import SEO from "../components/Seo/seo";
import ValuationForm from "../components/forms/valution-form";
import LandingImg from "../images/valuation-bg.png";


function TeamDetail(props) {


  return (
  <>
  <div className="wrapper">
    {/* header start */}
    <Header />
    {/* header end */}
    {/* content start */}
    <div className="content">
  <section className="section-contact team-details">
          <div className="contact-img contact-imgx animate__animated">
          <img src={LandingImg} alt="img" />
          </div>
          <Container>
            <Row className="justify-content-xl-end">
              <Col className="col-xl-6 textContact animate__animated ">
                <ValuationForm />
              </Col>
            </Row>
          </Container>
  </section>     

  </div>
  </div>
  </>
)
}
export default TeamDetail

