import React from 'react';
import { Container } from "react-bootstrap"
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const SINGLE_STAFF = gql`
query GetAreaDetails ($Name: String!){
    teams (where:{Name:$Name}) {
        Name
        id
        Staff_Image{
          url
        }
        Email
        Designation
        Phone
        imagetransforms
    }
 }
`;

function GetStaffdetails(props) {
    const { loading, error, data } = useQuery(SINGLE_STAFF, {
        variables: { Name: props.thankyoustaff }
      });
      
      if (loading) return (
        <div className="static-details gql-loading-screen d-none">
            <Container>
            <div className="gql-loading">Loading ...</div>
            </Container>
        </div>);
            return (
                <>
            {data.teams.map((staff, key) => {

                let processedImages = JSON.stringify({});
                if (staff?.imagetransforms?.Staff_Image_Transforms) {
                processedImages = staff.imagetransforms.Staff_Image_Transforms;
                }
                return (
                
            <div className="profile-details d-flex align-items-center">
                <figure className="profile-img">
                  <ImageTransform imagesources={staff.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.avartar"attr={{ alt: staff.Name+' ' + staff.Designation+' - Orlando Reid' }}
					imagetransformresult={processedImages} id={staff.id} testparam={true} />
                </figure>
                <div className="profile-desc">
                  <span className="user-name">{staff.Name}</span>
                  <span className="designation">{staff.Designation}</span>
                  <ul className="d-flex">
                    <li>
                      <a href={`tel:${staff.Phone}`}>{staff.Phone}</a>
                    </li>
                    <li>
                      <a href={`mailto:${staff.Email}`}>Email</a>
                    </li>
                  </ul>
                </div>
              </div>
                )
})}
            </>
        )
}

export default GetStaffdetails